import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenFromLocalStorage } from "../../utils/localStorage";
import axiosInstance from "../../utils/axios";
import { getMachines, setMachineValues } from "./machineSlice";
import { setCustomerValues } from "./customerSlice";
import moment, { duration } from "moment";

const initialCartItem = {
    // id: Math.ceil(Math.random() * 999),
    category: "",
    machine: "",
    brand: "",
    protectType: "",
    productionYear: "",
    serialNumber: "",
    insuranceStartDate: "",
    insuranceEndDate: "",
    description: "",
    purchasePrice: "",
    machineObj: "",
    categoryObj: "",
    deductible: "",
    fullProduction: "",
    hull: "",
    mtv: "",
    discountsOptions: [],
    extraPrices: [],
    protectFactor: "",
    price: "",
    basePrice: "",
    paymentPeriod: "per-month",
    discountsList: [],
    discountFactor: null,
    machineType: "",
    includedPolicyholder: "",
};

const initialState = {
    isLoading: false,
    machines: [],
    editingItemId: null,
    initialCartItem,
    baseMachines: [],
    baseTotal: 0,
    total: Number((240 / 10).toFixed(2)),
    targetExtraPrice: null,
    protectType: "",
    paymentPeriod: "per-month",
    editing: false,
    country: "austria",
};

export const fillContractInfo = createAsyncThunk(
    "contract/get-full-contract",
    async (id, thunkAPI) => {
        try {
            const token = getTokenFromLocalStorage();

            if (token) {
                const contractResponse = await axiosInstance.get(
                    `/api/distributor/contracts/full/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                    }
                );
                const { result } = await contractResponse.data;

                const machine = {
                    // id:Math.ceil(Math.random()*1000),
                    discountsList: result.machines[0].discountsList,
                    selectedMachine: result.machines[0].machine,
                    selectedCategory: result.machines[0].category,
                    machine: result.machines[0].machine._id,
                    category: result.machines[0].category._id,
                    brand: result.machines[0].brand,
                    productionYear: result.machines[0].productionYear,
                    serialNumber: result.machines[0].serialNumber,
                    insuranceStartDate: result.machines[0].insuranceStartDate,
                    insuranceEndDate: result.machines[0].insuranceEndDate,
                    description: result.description,
                    purchasePrice: result.machines[0].purchasePrice,
                    deductible: result.machines[0].deductible,
                    protectType: result.machines[0].protectType,
                    discountsOptions: result.machines[0].discountsOptions,
                    protectFactor: result.machines[0].protectFactor,
                    extraPrices: result.machines[0].extraExpenses,
                    machineObj: result.machines[0].machine,
                    categoryObj: result.machines[0].category,
                    price: result.machines[0].price,
                    machineType: result.machines[0].machineType,
                    duration: result.machines[0].duration,
                    mainDue: result.machines[0].mainDue,
                    includedPolicyholder:
                        result.machines[0].includedPolicyholder,
                    paymentPeriod: result.machines[0].paymentPeriod,
                    hull: 1,
                    mtv: 1,
                    fullProduction: 1,
                };
                thunkAPI.dispatch(
                    getMachines(result?.machines[0]?.category?._id)
                );
                thunkAPI.dispatch(setMachineValues({ machine }));
                thunkAPI.dispatch(
                    setCustomerValues({
                        customer: result.customer,
                        paymentDetails: {
                            swiftCode: result?.customer?.swiftCode || "",
                            ibanNumber: result?.customer?.ibanNumber || "",
                            bankName: result?.customer?.bankName || "",
                        },
                    })
                );
                return { result, machine };
            }
        } catch (error) {
            const { message } = error.response.data;
            console.log(`💥 ${message}`);
        }
    }
);

const cartItemsSlice = createSlice({
    name: "calculatorSlice",
    initialState,
    reducers: {
        deleteItem: (state, action) => {
            const { itemId } = { ...action.payload };
            state.machines = state.machines.filter((i) => i.id !== itemId);
        },
        updateItem: (state, action) => {
            const { itemId, name, value } = { ...action.payload };
            let item = [...state.baseMachines].find((i) => i.id === itemId);
            if (!item) {
                item = [...state.baseMachines][itemId];
            }
            if (name === "category") {
                try {
                    item.category = value;
                } catch (error) { }
            }
            if (name === "categoryObj") {
                item.categoryObj = value;
            }

            if (name === "machine") {
                item.machine = value.machine;
                item.discountsList = value.discountsList;
                item.deductible = value.discountsList[0].deductible;
                // item.protectType = "fullProduction";
                item.protectFactor = value.discountsList[0].fullProduction;
            }
            if (name === "machineObj") {
                item.machineObj = value.payload.result;
            }
            if (name === "serialNumber") {
                item.serialNumber = value;
            }

            if (name === "brand") {
                item.brand = value;
            }

            if (name === "productionYear") {
                let discounts = [...item.discountsOptions];
                const inList = discounts
                    .map((i) => Number(i.value))
                    .includes(5);
                const currentYear = new Date().getFullYear();
                if (Number(value) !== currentYear) {
                    if (inList) {
                        discounts.pop({
                            id: 4,
                            label: "Neuwertentschädigung innerhalb der ersten 24 Monaten (+5%)",
                            value: 5,
                        });
                    }
                }
                // else {
                //   discounts = discounts.filter((i) => i.value !== 5);
                // }
                item.discountsOptions = discounts;
                item.productionYear = value;
                state.initialCartItem = {
                    ...state.initialCartItem,
                    discountsOptions: item.discountsOptions,
                };
            }
            if (name === "insuranceStartDate") {
                item.insuranceStartDate = value;
            }

            if (name === "duration") {
                let discounts = [...item.discountsOptions];
                const inList = discounts
                    .map((i) => Number(i.value))
                    .includes(-10);

            
                if (Number(value) !== 1) {
                    if (
                        !discounts
                            .map((i) => i.label)
                            .includes(
                                "Mehrjährigkeitsrabatt bei einer Laufzeit von 3 Jahren (-10%)"
                            )
                    ) {
                        discounts.push({
                            id: 6,
                            label: "Mehrjährigkeitsrabatt bei einer Laufzeit von 3 Jahren (-10%)",
                            value: -10,
                        });
                    }
                } else {
                    discounts = discounts.filter(
                        (i) =>
                            i.label !==
                            "Mehrjährigkeitsrabatt bei einer Laufzeit von 3 Jahren (-10%)"
                    );
                }

                item.discountsOptions = discounts;
                item.duration = value;
                state.initialCartItem = {
                    ...state.initialCartItem,
                    discountsOptions: item.discountsOptions,
                };
            }

            if (name === "machineType") {
                item.machineType = value;
            }

            if (name === "mainDue") {
                item.mainDue = value;
            }

            if (name === "purchasePrice") {
                item.purchasePrice = value;
            }

            if (name === "extraPrices") {
                const { id, title, price } = value;
                const existItem = [...item.extraPrices].find((i) => i.id == id);
                if (!existItem || existItem === undefined) {
                    item.extraPrices.push({
                        price: value.price,
                        title: value.title,
                        id: id,
                    });
                    state.initialCartItem.extraPrices.push({
                        price: value.price,
                        title: value.title,
                        id: id,
                    });
                } else {
                    item.extraPrices = [...item.extraPrices].filter(
                        (i) => i.id !== id
                    );
                    state.initialCartItem.extraPrices = item.extraPrices;
                }

                const totalExtra = [...item.extraPrices]
                    .map((i) => i.price)
                    .reduce((acc, current) => Number(acc) + Number(current), 0);

                item.price = Number(item.price) + totalExtra;
            }

            if (name === "includedPolicyholder") {
                item.includedPolicyholder = value.includedPolicyholder;
                state.initialCartItem.includedPolicyholder =
                    value.includedPolicyholder;
            }

            if (name === "discountsOptions") {
                let currentYear = new Date().getFullYear();
                // alert(Number(state.initialCartItem.productionYear) === currentYear)
                if (
                    Number(state.initialCartItem.productionYear) === currentYear
                ) {
                    if (value && value.id !== 6) {
                        const inList = [...item.discountsOptions].find((i) => {
                            return { ...i }.value === value.value;
                        });
                        if (inList) {
                            item.discountsOptions = [
                                ...item.discountsOptions,
                            ].filter((i) => {
                                return { ...i }.value !== value.value;
                            });
                        } else {
                            item.discountsOptions.push(value);
                        }

                        state.initialCartItem = {
                            ...state.initialCartItem,
                            discountsOptions: item.discountsOptions,
                        };
                    }
                } else {
                    if (value && value.id !== 6 && value.id !== 4) {
                        const inList = [...item.discountsOptions].find((i) => {
                            return { ...i }.value === value.value;
                        });
                        if (inList) {
                            item.discountsOptions = [
                                ...item.discountsOptions,
                            ].filter((i) => {
                                return { ...i }.value !== value.value;
                            });
                        } else {
                            item.discountsOptions.push(value);
                        }

                        state.initialCartItem = {
                            ...state.initialCartItem,
                            discountsOptions: item.discountsOptions,
                        };
                    }
                }
            }
            //state.machines.push(item);
            state.machines = [...state.machines].map((itemN) => {
                if (itemN.id === item.id) {
                    itemN = item;
                }
                return itemN;
            });
            state.initialCartItem = item;
            state.baseMachines = [...state.machines];
            state.baseTotal = state.total;
        },
        defaultMachine: (state, action) => {
            state.editing = true;
            let selectedItemLatest = state.machines[state.machines.length - 1];
            state.initialCartItem = { ...selectedItemLatest };
        },

        updateOneMachine: (state, action) => {
            const { itemId } = action.payload;
            state.editing = true;
            const selectedItem = [...state.machines].find(
                (i) => i.id === itemId
            );
            state.initialCartItem = { ...selectedItem };
        },
        initMachine: (state, action) => {
            const machine = { ...action.payload };

            state.machines.push({
                ...machine,
                id: state.machines.length,
            });

            let machines = [...state.machines];
            if (machines.length >= 3 && machines.length < 6) {
                machines.map((machine) => {
                    const discounts = [...{ ...machine }.discountsOptions];
                    const exists = discounts.find((d) => {
                        const discountItem = { ...d };
                        return (
                            discountItem.label ===
                            "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
                            discountItem.label ===
                            "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                        );
                    });

                    if (!exists) {
                        machine.discountsOptions.push({
                            id: 7,
                            label: "Mengenrabatt 3 bis 5 Maschinen (-7%)",
                            value: -7,
                        });
                    }
                });

                
            } else if (machines.length >= 6 && machines.length <= 10) {
                machines.map((machine) => {
                    const discounts = [...{ ...machine }.discountsOptions];
                    if (machine.duration !== Number(1)) {
                        machine.discountsOptions.push({
                            id: 10,
                            label: "Mengenrabatt 6 bis 10 Maschinen (-10%)",
                            value: -10,
                        });
                    }
                    machine.discountsOptions = discounts.filter((d) => {
                        return (
                            { ...d }.label !==
                            "Mengenrabatt 3 bis 5 Maschinen (-7%)"
                        );
                    });

                    const exists = discounts.find((d) => {
                        const discountItem = { ...d };
                        return (
                            discountItem.label ===
                            "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                        );
                    });

                    if (!exists) {
                        machine.discountsOptions.push({
                            id: 10,
                            label: "Mengenrabatt 6 bis 10 Maschinen (-10%)",
                            value: -10,
                        });
                    }
                });
            } else {
                machines.map((machine) => {
                    const discounts = [...{ ...machine }.discountsOptions];
                    machine.discountsOptions = discounts.filter((d) => {
                        return (
                            { ...d }.label !==
                            "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
                            { ...d }.label !==
                            "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                        );
                    });
                });
            }

            state.machines = machines;
            state.initialCartItem = [...state.machines].reverse()[0];
            state.baseMachines = [...state.machines];
            state.baseTotal = state.total;
        },
        resetinitialCartItem: (state, action) => {
            state.initialCartItem = {
                ...state.initialCartItem,
                purchasePrice: 0,
                extraPrices: [],
                price: 0,
                basePrice: 0,
            };
        },
        removeLastItem: (state, action) => {
            let lastItem = [...state.machines][0];

            const newMachines = [...state.machines].filter(
                (i) => i.id !== lastItem.id
            );

            state.machines = newMachines;
        },
        calculateOneItem: (state, action) => {
            // find machine from machines list
            const { itemId } = { ...action.payload };
            const item = [...state.machines].find((item) => item.id == itemId);

            // set payment period value
            let paymentPeriod =
                state.paymentPeriod === "per-month"
                    ? 1 / 12
                    : state.paymentPeriod === "quarterly"
                        ? 1 / 4
                        : state.paymentPeriod === "half-yearly"
                            ? 1 / 2
                            : state.paymentPeriod === "yearly"
                                ? 1
                                : null;

            // find total of selected discount
            const discounts =
                [...item?.discountsOptions].map((i) => {
                    return { ...i }.value;
                }) || [];
            console.log(discounts);

            // define tax rate value
            let taxRate =
                state.country === "germany"
                    ? 119
                    : discounts.includes(-12)
                        ? 111
                        : 111.4;

            // find cost with tax rate

            item.price =
                (Number(item.purchasePrice || 0) +
                    Number(item.purchasePrice || 0) * (taxRate / 100)) *
                paymentPeriod;

            // total of extra accessories
            const totalExtra = [...item.extraPrices]
                .map((i) => i.price)
                .reduce((acc, current) => Number(acc) + Number(current), 0);

            if (totalExtra) {
                item.price =
                    (Number(item.purchasePrice) +
                        totalExtra +
                        (Number(item.purchasePrice) + totalExtra) *
                        (taxRate / 100)) *
                    paymentPeriod;
            }

            if (item.protectFactor) {
                item.price =
                    ((Number(item.purchasePrice) + totalExtra) *
                        (item.protectFactor / 1000) +
                        (Number(item.purchasePrice) + totalExtra) *
                        (item.protectFactor / 1000) *
                        (taxRate / 100)) *
                    paymentPeriod;
            }
            // calculate cost depends on machines count

            // find total discounts
            //HERE

            // let machines = [...state.machines];
            // if (machines.length >= 3 && machines.length < 6) {
            //     const discounts = [...{ ...item }.discountsOptions];
            //     const exists = discounts.find((d) => {
            //         const discountItem = { ...d };
            //         return (
            //             discountItem.label ===
            //                 "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
            //             discountItem.label ===
            //                 "Mengenrabatt 6 bis 10 Maschinen (-10%)"
            //         );
            //     });
            // } else if (machines.length >= 6 && machines.length <= 10) {
            //     const discounts = [...{ ...item }.discountsOptions];
            //     if (item.duration !== Number(1)) {
            //         item.discountsOptions.push({
            //             id: 10,
            //             label: "Mengenrabatt 6 bis 10 Maschinen (-10%)",
            //             value: -10,
            //         });
            //     }
            //     item.discountsOptions = discounts.filter((d) => {
            //         return (
            //             { ...d }.label !==
            //             "Mengenrabatt 3 bis 5 Maschinen (-7%)"
            //         );
            //     });

            //     const exists = discounts.find((d) => {
            //         const discountItem = { ...d };
            //         return (
            //             discountItem.label ===
            //             "Mengenrabatt 6 bis 10 Maschinen (-10%)"
            //         );
            //     });

            //     if (!exists) {
            //         item.discountsOptions.push({
            //             id: 10,
            //             label: "Mengenrabatt 6 bis 10 Maschinen (-10%)",
            //             value: -10,
            //         });
            //     }
            // } else {
            //     const discounts = [...{ ...item }.discountsOptions];
            //     item.discountsOptions = discounts.filter((d) => {
            //         return (
            //             { ...d }.label !==
            //                 "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
            //             { ...d }.label !==
            //                 "Mengenrabatt 6 bis 10 Maschinen (-10%)"
            //         );
            //     });
            // }
            ///////////
            let itemDiscounts = [...item.discountsOptions].map((i) =>
                Number(i.value)
            );
            let basePrice =
                (Number(item.purchasePrice) + totalExtra) *
                (Number(item.protectFactor) / 1000);
            for (let index = 0; index < itemDiscounts.length; index++) {
                const currentDiscount = itemDiscounts[index];
                let discountValue =
                    Number(basePrice.toFixed(2)) * (currentDiscount / 100);
                basePrice += discountValue;
            }
            basePrice = basePrice * (taxRate / 100) * paymentPeriod;

            item.price = Number(basePrice.toFixed(2));

            state.baseMachines = [...state.machines];
            state.initialCartItem.price = item.price;
            // state.total = state.total;
            item.basePrice = item.price;
        },

        calculateTotal: (state) => {
            const total = [...state.machines]
                .map((machine) => Number(machine.price))
                .reduce((acc, current) => acc + current, 0);

            if (total > 240 / 10) {
                state.total = total;
                state.baseMachines = [...state.machines];
                state.baseTotal = state.total;
            } else {
                state.total = Number((240 / 10).toFixed(2));
                state.baseMachines = [...state.machines];
                state.baseTotal = Number((240 / 10).toFixed(2));
            }
        },

        controlDiscounts: (state, action) => {
            let machines = [...state.machines];

            // for (let machine of machines) {
            //     const discounts = machine.discountsOptions;
            //     const target = discounts.find((d) => {
            //         const discountItem = { ...d };

            //         return (
            //             discountItem.label ===
            //                 "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
            //             discountItem.label ===
            //                 "Mengenrabatt 6 bis 10 Maschinen (-10%)"
            //         );
            //     });

            //     if ({ ...target }) {
            //         machine.discountsOptions.pop({ ...target });
            //     }

            //     // machine.discountsOptions = machine.discountsOptions.filter(d => {
            //     //   const item = { ...d }
            //     //   return item.label !== "Mengenrabatt 3 bis 5 Maschinen (-7%)" || item.label !== "Mengenrabatt 6 bis 10 Maschinen (-10%)"
            //     // })
            // }

            if (machines.length >= 3 && machines.length < 6) {
                machines.map((machine) => {
                    const discounts = [...{ ...machine }.discountsOptions];
                    const exists = discounts.find((d) => {
                        const discountItem = { ...d };
                        return (
                            discountItem.label ===
                            "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
                            discountItem.label ===
                            "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                        );
                    });

                    if (!exists) {
                        machine.discountsOptions.push({
                            id: 7,
                            label: "Mengenrabatt 3 bis 5 Maschinen (-7%)",
                            value: -7,
                        });
                    }
                });
            } else if (machines.length >= 6 && machines.length <= 10) {
                machines.map((machine) => {
                    const discounts = [...{ ...machine }.discountsOptions];

                    machine.discountsOptions = discounts.filter((d) => {
                        return (
                            { ...d }.label !==
                            "Mengenrabatt 3 bis 5 Maschinen (-7%)"
                        );
                    });

                    const exists = discounts.find((d) => {
                        const discountItem = { ...d };
                        return (
                            discountItem.label ===
                            "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                        );
                    });

                    if (!exists) {
                        machine.discountsOptions.push({
                            id: 10,
                            label: "Mengenrabatt 6 bis 10 Maschinen (-10%)",
                            value: -10,
                        });
                    }
                });
            } else {
                machines.map((machine) => {
                    const discounts = [...{ ...machine }.discountsOptions];
                    machine.discountsOptions = discounts.filter((d) => {
                        return (
                            { ...d }.label !==
                            "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
                            { ...d }.label !==
                            "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                        );
                    });
                });
            }

            state.machines = machines;
        },

        removeMachine: (state, action) => {
            const { machineId } = action.payload;

            const item = [...state.machines].find(
                (item) => item.id === machineId
            );

            if (item) {
                state.machines = state.machines.filter(
                    (machine) => machine.id !== machineId
                );

                state.total = [...state.machines]
                    .map((machine) => machine.price)
                    .reduce((acc, current) => Number(acc) + Number(current), 0);
            }

            state.baseMachines = [...state.machines];
            state.baseTotal = state.total;

            let machines = [...state.machines];

            for (let machine of machines) {
                const discounts = machine.discountsOptions;
                const target = discounts.find((d) => {
                    const discountItem = { ...d };
                    return (
                        discountItem.label ===
                        "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
                        discountItem.label ===
                        "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                    );
                });

                if ({ ...target }) {
                    machine.discountsOptions.pop({ ...target });
                }
            }

            if (machines.length >= 3 && machines.length < 6) {
                machines.map((machine) => {
                    const discounts = [...{ ...machine }.discountsOptions];
                    const exists = discounts.find((d) => {
                        const discountItem = { ...d };
                        return (
                            discountItem.label ===
                            "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
                            discountItem.label ===
                            "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                        );
                    });

                    if (exists) {
                        machine.discountsOptions.pop({
                            id: 7,
                            label: "Mengenrabatt 3 bis 5 Maschinen (-7%)",
                            value: -7,
                        });
                    }
                });
            } else if (machines.length >= 6 && machines.length <= 10) {
                machines.map((machine) => {
                    const discounts = [...{ ...machine }.discountsOptions];
                    machine.discountsOptions = discounts.filter((d) => {
                        return (
                            { ...d }.label !==
                            "Mengenrabatt 3 bis 5 Maschinen (-7%)"
                        );
                    });

                    const exists = discounts.find((d) => {
                        const discountItem = { ...d };
                        return (
                            discountItem.label ===
                            "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                        );
                    });

                    if (exists) {
                        machine.discountsOptions.pop({
                            id: 10,
                            label: "Mengenrabatt 6 bis 10 Maschinen (-10%)",
                            value: -10,
                        });
                    }
                });
            } else {
                machines.map((machine) => {
                    const discounts = [...{ ...machine }.discountsOptions];
                    machine.discountsOptions = discounts.filter((d) => {
                        return (
                            { ...d }.label !==
                            "Mengenrabatt 3 bis 5 Maschinen (-7%)" ||
                            { ...d }.label !==
                            "Mengenrabatt 6 bis 10 Maschinen (-10%)"
                        );
                    });
                });
            }
            // update ID
            const newMachines = [...machines].map((machine) => {
                return {
                    ...machine,
                    // id: machine.id - 1,
                };
            });

            const lastMachine = newMachines[newMachines.length - 1];

            state.initialCartItem = { ...lastMachine };
            state.machines = newMachines;
        },
        removeLastMachine: (state, action) => {
            let { machines } = action.payload;
            state.machines = machines;
            state.total = machines
                .map((m) => Number(m.price))
                .reduce((current, next) => current + next, 0);
        },
        clearTargetExtraPrice: (state, action) => {
            state.targetExtraPrice = null;
        },

        updateExtraPriceItem: (state, action) => {
            const { itemId, name, value } = action.payload;
            const { title, price, id } = value;
            const item = [...state.machines].find((item) => item.id === itemId);
            const extraPrice = [...item.extraPrices].find(
                (item) => item.id === id
            );
            extraPrice.title = title;
            extraPrice.price = price;
            state.initialCartItem.extraPrices = [...item.extraPrices];
        },

        setExtraPriceItem: (state, action) => {
            const { itemId, name, value } = action.payload;
            const item = [...state.machines].find((item) => item.id === itemId);
            const extraPrice = [...item.extraPrices].find(
                (item) => item.id === value.id
            );
            if (extraPrice) {
                state.targetExtraPrice = { ...extraPrice };
            }
        },

        updatePaymentPeriod: (state, action) => {
            const { paymentPeriod } = action.payload;
            // let newTotal = 0;
            state.machines = [...state.machines].map((item) => {
                return {
                    ...item,
                    paymentPeriod: paymentPeriod,
                };
            });
            state.paymentPeriod = paymentPeriod;
            // state.total = newTotal;
        },

        updateDescription: (state, action) => {
            const { value } = action.payload;
            state.description = value;
        },
        updateCountry: (state, action) => {
            const { country } = action.payload;
            state.country = country;
            let total = 0;
            for (let machine of [...state.machines]) {
                let paymentPeriod =
                    state.paymentPeriod === "per-month"
                        ? 1 / 12
                        : state.paymentPeriod === "quarterly"
                            ? 1 / 4
                            : state.paymentPeriod === "half-yearly"
                                ? 1 / 2
                                : state.paymentPeriod === "yearly"
                                    ? 1
                                    : null;

                // find total of selected discount
                const discounts = [...machine.discountsOptions].map((item) => {
                    return { ...item }.value;
                });

                // define tax rate value
                let taxRate =
                    country === "germany"
                        ? 119
                        : discounts.includes(-12)
                            ? 111
                            : 111.4;

                // find cost with tax rate
                let newPrice =
                    (Number(machine.purchasePrice) +
                        Number(machine.purchasePrice) * (taxRate / 100)) *
                    paymentPeriod;

                // total of extra accessories
                const totalExtra = [...machine.extraPrices]
                    .map((i) => i.price)
                    .reduce((acc, current) => Number(acc) + Number(current), 0);

                if (totalExtra) {
                    newPrice =
                        (Number(machine.purchasePrice) +
                            totalExtra +
                            (Number(machine.purchasePrice) + totalExtra) *
                            (taxRate / 100)) *
                        paymentPeriod;
                }

                if (machine.protectFactor) {
                    newPrice =
                        ((Number(machine.purchasePrice) + totalExtra) *
                            (machine.protectFactor / 1000) +
                            (Number(machine.purchasePrice) + totalExtra) *
                            (machine.protectFactor / 1000) *
                            (taxRate / 100)) *
                        paymentPeriod;
                }

                // calculate cost depends on machines count
                if ([...state.machines].length >= 3) {
                    [...machine.discountsOptions].push({
                        id: 6,
                        label: "Mehrjährigkeitsrabatt bei einer Laufzeit von 3 Jahren (-10%)",
                        value: -10,
                    });
                }

                if (
                    [...state.machines].length >= 3 &&
                    [...state.machines].length <= 5
                ) {
                    [...machine.discountsOptions].push({
                        id: 7,
                        label: "Neuzugang Rabattartikel",
                        value: 7,
                    });
                } else if (
                    [...state.machines].length >= 6 &&
                    [...state.machines].length <= 10
                ) {
                    [...machine.discountsOptions].filter((i) => i.id !== 7);
                    [...machine.discountsOptions].push({
                        id: 7,
                        label: "Neuzugang Rabattartikel",
                        value: 10,
                    });
                } else {
                    [...machine.discountsOptions].filter((i) => i.id !== 7);
                }

                // find total discounts
                const totalDiscounts = [...machine.discountsOptions]
                    .map((i) => i.value)
                    .reduce((acc, current) => acc + current, 0);
                // if (totalDiscounts) {
                //   let discounts = [...machine.discountsOptions].map(i=>Number(i.value));
                // let basePrice = (Number(machine.purchasePrice) + totalExtra) *(Number(machine.protectFactor) / 1000);
                // for (let index = 0; index < discounts.length; index++) {
                //   const currentDiscount = discounts[index];
                //   basePrice += basePrice * (currentDiscount/100);
                //   console.log({currentDiscount,basePrice,discountValue:basePrice * (currentDiscount/100)});
                // }
                // basePrice = basePrice * (taxRate /100) * paymentPeriod;
                // console.log("payment period",paymentPeriod,"tax rate",taxRate,"price with tax rate",basePrice);
                // machine.price = basePrice;
                // newPrice=basePrice;
                // }

                ///////////
                let itemDiscounts = [...machine.discountsOptions].map((i) =>
                    Number(i.value)
                );
                let basePrice =
                    (Number(machine.purchasePrice) + totalExtra) *
                    (Number(machine.protectFactor) / 1000);
                for (let index = 0; index < itemDiscounts.length; index++) {
                    const currentDiscount = itemDiscounts[index];
                    let discountValue =
                        Number(basePrice.toFixed(2)) * (currentDiscount / 100);
                    basePrice += discountValue;
                }
                basePrice = basePrice * (taxRate / 100) * paymentPeriod;

                newPrice = Number(basePrice.toFixed(2));
                ///////////
                machine.price = newPrice;
                total += newPrice;
                state.baseMachines = [...state.machines];
                state.baseTotal = state.total;
                state.total = total;
            }
        },

        updateSerialNumber: (state, action) => {
            const { itemId, value } = { ...action.payload };
            const selectedItem = [...state.machines].find(
                (m) => m.machine === itemId
            );
            if (selectedItem) {
                selectedItem.serialNumber = value;
            }
        },
        updateDeductibleValue: (state, action) => {
            const { itemId, value } = { ...action.payload };

            const selectedItem = [...state.machines].find(
                (m) => m.machine === Number(itemId)
            );
            if (selectedItem) {
                selectedItem.deductible = value.deductible;
            }
            //state.protectType = "";
        },

        editDeductibleValue: (state, action) => {
            const { itemId, value } = { ...action.payload };

            const selectedItem = [...state.machines].find(
                (m) => m.id === Number(itemId)
            );
            selectedItem.deductible = value;
            for (let discount of selectedItem.discountsList) {
                if ({ ...discount }.deductible == value) {
                    state.protectFactor = { ...discount }[
                        selectedItem.protectType
                    ];
                    selectedItem.protectFactor = { ...discount }[
                        selectedItem.protectType
                    ];
                }
            }
        },

        updateProtectType: (state, action) => {
            const { value, itemId } = { ...action.payload };
            const selectedItem = state.machines.find((m) => m.id === itemId);
            state.protectType = value;
            selectedItem.protectType = value || "mtv";
            state.initialCartItem = selectedItem;

            for (let machine of [...state.machines]) {
                for (let discount of [...machine.discountsList]) {
                    const { deductible, fullProduction, hull, mtv } = {
                        ...discount,
                    };

                    if (Number(deductible) === Number(machine.deductible)) {
                        machine.mtv = mtv;
                        machine.hull = hull;
                        machine.fullProduction = fullProduction;
                    }
                }
            }

            for (let machine of [...state.machines]) {
                if (machine.protectType == value) {
                    if (value === "mtv") {
                        machine.protectFactor = machine.mtv;
                    } else if (value === "hull") {
                        machine.protectFactor = machine.hull;
                    } else if (value === "fullProduction") {
                        machine.protectFactor = machine.fullProduction;
                    }
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fillContractInfo.pending, (state, action) => {
            return { ...state, loading: false };
        });

        builder.addCase(fillContractInfo.fulfilled, (state, action) => {
            if (action.payload !== undefined) {
                const { result } = action.payload;

                const newMachines = result.machines.map((m, x) => {
                    let protectFactor;
                    if (m.protectType === "mtv") {
                        protectFactor = m.machine.discounts.find(
                            (i) => i.deductible === m.deductible
                        ).mtv;
                    } else if (m.protectType === "hull") {
                        protectFactor = m.machine.discounts.find(
                            (i) => i.deductible === m.deductible
                        ).hull;
                    } else if (m.protectType === "fullProduction") {
                        protectFactor = m.machine.discounts.find(
                            (i) => i.deductible === m.deductible
                        ).fullProduction;
                    }

                    return {
                        //id:m.machine._id,
                        id: x,
                        discountsList: m.machine.discounts,
                        selectedMachine: m.machine,
                        selectedCategory: m.category,
                        machine: m.machine._id,
                        category: m.category._id,
                        brand: m.brand,
                        productionYear: m.productionYear,
                        serialNumber: m.serialNumber,
                        insuranceStartDate: moment(m.insuranceStartDate).format(
                            "yyyy-MM-DD"
                        ),
                        insuranceEndDate: m.insuranceEndDate,
                        description: m.description,
                        purchasePrice: m.purchasePrice,
                        discountsOptions: m.discountsOptions || [],
                        protectFactor: protectFactor,
                        extraPrices: m.extraExpenses,
                        machineObj: m.machine,
                        categoryObj: m.category,
                        price: m.price,
                        machineType: m.machineType,
                        duration: m.duration,
                        mainDue: moment(m.mainDue).format("MM.DD"),
                        includedPolicyholder: m.includedPolicyholder,
                        paymentPeriod: m.paymentPeriod,
                        deductible: m.deductible,
                        protectType: m.protectType,
                        fullProduction: m.machine.discounts.find(
                            (i) => i.deductible === m.deductible
                        ).fullProduction,
                        hull: m.machine.discounts.find(
                            (i) => i.deductible === m.deductible
                        ).hull,
                        mtv: m.machine.discounts.find(
                            (i) => i.deductible === m.deductible
                        ).mtv,
                        // hull:10,
                        // mtv:11
                    };
                });

                for (let machine of newMachines) {
                    calculateOneItem({
                        itemId: machine.id,
                        country: state.country ? state.country : "austria",
                    });

                    //calculateOneItem.setActiveModule(state, action);
                }

                const newTotal = newMachines
                    .map((m) => Number(m.price))
                    .reduce((acc, next) => acc + next, 0);

                return {
                    ...state,
                    country: result.country,
                    description: result.description,
                    paymentPeriod: [...newMachines].reverse()[0].paymentPeriod,
                    machines: newMachines,
                    baseMachines: newMachines,
                    initialCartItem: [...newMachines].reverse()[0],
                    total: newTotal,
                    baseTotal: newTotal,
                    protectType: [...newMachines].reverse()[0].protectType,
                };
            }
        });
    },
});

export const {
    removeMachine,
    updatePaymentPeriod,
    updateItem,
    calculateOneItem,
    // calculateAll,
    initMachine,
    calculateTotal,
    updateExtraPriceItem,
    setExtraPriceItem,
    clearTargetExtraPrice,
    updateDeductibleValue,
    editDeductibleValue,
    updateSerialNumber,
    updateProtectType,
    removeLastItem,
    removeLastMachine,
    updateCountry,
    updateDescription,
    updateOneMachine,
    defaultMachine,
    deleteItem,
    controlDiscounts,
    resetinitialCartItem,
} = cartItemsSlice.actions;

export default cartItemsSlice.reducer;
